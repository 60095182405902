function updateSongTitleToMetaData(title) {
    $("#mainContainerTitle").text(title);
}

function displayFileName(file_name, formatted_file_name, full_title) {
    if (!full_title.includes(formatted_file_name)) {
        $("#fileName").text(file_name);
        $("#fileName").css("display", "block");
    }
    else {
        $("#fileName").text("");
        $("#fileName").css("display", "none");
    }
}

function displayMusicPlayer() {
    $("#player").css("display", "block");
}

function playInThePlayer(file_url) {
    Amplitude.playNow({ "url": file_url });
}

function changeBackgroundImage(picture) {
    if(picture != undefined){
        const image64 = btoa(String.fromCharCode.apply(null, new Uint8Array(picture["data"])));
        $("#background").attr("src", `data:image/${picture["format"]};base64,${image64}`);
    }
    else {
        $("#background").attr("src", null);
    }
}
export {
    updateSongTitleToMetaData,
    displayFileName,
    displayMusicPlayer,
    playInThePlayer,
    changeBackgroundImage
};