class MusicMetaData{
    constructor(metaData) {
        this.title = metaData.title;
        this.album = metaData.album;
        this.artist = metaData.artist;
        this.file_name = metaData.file_name.replace(".mp3", "");
    }
    getFormatedFullTitle() {
        let full_title = [
            this.title || this.file_name,
            this.album,
            this.artist
        ].filter(Boolean).join(" - ");

        return full_title;
    }
}

export default MusicMetaData;