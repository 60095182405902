import { changeBackgroundImage, displayFileName, displayMusicPlayer, playInThePlayer, updateSongTitleToMetaData } from "./InterfaceFunctions";
import MusicMetaData from "./MusicMetaData";

function fileUploadHandler() {
    $("#file").on("change", async (e) => {
        const file = e.currentTarget.files[0];
        const media_tag = await getMediaTag(file);
        const file_meta_data = new MusicMetaData({
            title: media_tag["tags"]["title"],
            album: media_tag["tags"]["album"],
            artist: media_tag["tags"]["artist"],
            file_name: file.name
        });
        const full_title = file_meta_data.getFormatedFullTitle();
        updateSongTitleToMetaData(full_title);
        displayFileName(file.name, file_meta_data.file_name, full_title);

        const file_url = URL.createObjectURL(file);
        displayMusicPlayer();
        playInThePlayer(file_url);
        console.log(media_tag);
        changeBackgroundImage(media_tag["tags"]["picture"]);
        console.log(media_tag);
    });
}

function getMediaTag(file) {
    return new Promise((resolve, reject) => {
        jsmediatags.read(file, {
            onSuccess: function (tag) {
                resolve(tag);
            },
            onError: function(error) {
                reject(error);
            }
        });
    });
}

export {
    fileUploadHandler
};
