function listenToBrowseButtonEnterOrSpaceClick() {
    $('#browseButton').on('keypress keyup', function (event) {
        if (areEnterOrSpaceKeyPressed(event)) {
            event.preventDefault();
            $('#file').trigger("click");
        }
    });
}

function areEnterOrSpaceKeyPressed(event) {
    return event.which === 32 || event.which === 13;
}

export {
    listenToBrowseButtonEnterOrSpaceClick
};