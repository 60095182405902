import '../styles/style.scss';
import { listenToBrowseButtonEnterOrSpaceClick } from "./BrowseButton";
import { fileUploadHandler } from "./FileUploadHandler";
import { listentoSoundButtonClick } from "./SoundButton";

window.onload = function () {
  listenToBrowseButtonEnterOrSpaceClick();
  fileUploadHandler();
  listentoSoundButtonClick();
  Amplitude.init({
    songs: [
      {
        "url": "test"
      }
    ]
  });
};
