function listentoSoundButtonClick(){
    $("#soundButton").on("click", (evt) => {
        $("#volumeSlider").toggle();
    });
    $("body").on("click", function (evt) {
        if (evt.target.id == "volumeSlider" || evt.target.id == "soundButton")
            return;
        $("#volumeSlider").hide();
    });
}

export {
    listentoSoundButtonClick
};